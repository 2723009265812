<template>
    <div class="main-form" v-if="loaded">
        <div>
            <ValidationObserver class ref="form" v-if="!submitted">
                <form @submit.prevent="onSubmit">
                    <div class>
                        <div class="flex flex-wrap -mx-2">
                            <div
                                :key="index"
                                class="w-full px-2 sm:w-1/2"
                                v-for="(question, index) in questions"
                            >
                                <label :for="'q' + index" class="uppercase">{{ question.question }}</label>
                                <input-component
                                    :id="'q' + index"
                                    :name="question.question"
                                    :rules="question.rules"
                                    v-model="answers[index].answer"
                                ></input-component>
                            </div>
                        </div>
                        <div>
                            <button
                                class="px-12 py-2 text-xl text-white bg-black"
                                type="submit"
                            >Skicka</button>
                        </div>
                    </div>
                </form>
            </ValidationObserver>
            <div class="text-center" v-else>
                <h2 class="text-4xl font-bold tracking-wider">TACK!</h2>
                <p class="text-xl">Ditt stöd betyder otroligt mycket för oss!</p>
            </div>
        </div>
    </div>
</template>
<script>
import axios from 'axios'
import InputComponent from './InputComponent.vue'
export default {
    name: 'Form',
    data() {
        return {
            endpoint: 'https://formularfabriken.infab.io/api/surveys/61473d02-e77a-451e-bc86-a2164ea44889',
            started: false,
            questions: [],
            survey: {},
            answers: [],
            submitted: false,
            step: 1,
            loaded: false
        }
    },
    components: {
        InputComponent
    },
    created() {
        this.fetchFormData();
    },
    destroyed() {
    },
    methods: {
        handleScroll(event) {

            var y = window.scrollY
            if (y == 0 && !this.submitted) {
                this.started = false;
                return;
            }
            this.started = true;
        },
        validateForm() {
            this.$refs.form.validate();
        },
        startSurvey() {
            this.started = true;
            setTimeout(() => {
                this.$refs.startbutton.click()
            }, 700);
        },
        onSubmit() {
            this.$refs.form.validate().then(success => {
                if (!success) {
                    return;
                }

                this.postForm();
                // Resetting Values
                this.$nextTick(() => {
                    this.$refs.form.reset();
                });
            });

        },
        async postForm() {
            try {
                let payload = {
                    answers: this.answers
                }

                const { data } = await axios.post(this.endpoint + '/submit', payload);
                this.submitted = true;
            } catch (error) {
                console.log(error)
            }

        },
        async fetchFormData() {
            try {
                let payload = {
                    params: {
                        include: 'questions'
                    }
                }
                const { data } = await axios.get(this.endpoint, payload);
                this.questions = data.data.questions.data;
                // this.questions.push(data.data.questions.data[0])
                this.questions.forEach(question => {
                    this.answers.push({
                        question_id: question.id,
                        answer: ''
                    })
                });
                this.survey = data.data;
                this.loaded = true;
            } catch (error) {
                console.log(error)
            }
        }
    }
}
</script>
<style>
body {
    font-family: "futura-pt";
}
</style>