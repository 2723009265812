<template>
    <div>
        <ValidationProvider :rules="rules" v-slot="{ errors, classes }">
            <span>
                <input
                    :class="classes"
                    :type="type"
                    :value="value"
                    @input="updateValue($event.target.value)"
                    class="block w-full px-2 py-2 border focus:outline-none"
                    :name="name"
                    v-if="type == 'text'"
                />
                <textarea
                    :class="classes"
                    :value="value"
                    @input="updateValue($event.target.value)"
                    class="block w-full px-4 py-2 border-t border-c4blue-500 focus:outline-none"
                    ref="textarea"
                    rows="8"
                    v-else
                ></textarea>
                <div class="h-8">
                    <span class="h-6 text-xs text-red-500 error-text">{{ errors[0] }}</span>
                </div>
            </span>
        </ValidationProvider>
    </div>
</template>
<script>
import autosize from 'autosize';
import { ValidationProvider } from 'vee-validate';

export default {
    name: 'InputComponent',
    props: {
        value: {
            type: [String, Number],
            default: ''
        },
        type: {
            type: String,
            default: 'text'
        },
        rules: {
            type: String,
            default: ''
        },
        question: {
            type: String,
            default: ''
        },
        name: {
            type: String,
            default: 'text'
        },
        errorMessage: {
            type: String,
            default: 'Fältet är obligatoriskt'
        }
    },
    mounted() {
        let area = this.$refs.textarea;
        autosize(area);
    },
    watch: {
        value(val, old) {
            if (!old && val) {
                let area = this.$refs.textarea;
                this.$nextTick(() => {
                    autosize.update(area);
                })
            }
        }
    },
    methods: {
        updateValue(value) {
            this.$emit('input', value);
        },
    }
}
</script>
