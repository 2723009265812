import Vue from 'vue'
import VueRouter from 'vue-router'
import App from './App.vue'

Vue.config.productionTip = false
Vue.use(VueRouter)

import Validator from './config/validator'
import Form from './components/Form'
import StatsChart from './components/StatsChart'

const routes = [
    { path: '/', component: Form },
    { path: '/chart', component: StatsChart }
]

const router = new VueRouter({
    mode: 'history',
    routes // short for `routes: routes`
})

// CSS
import '@/assets/css/main.css'
new Vue({
    router:router,
    render: h => h(App)
}).$mount('#app')
// const app = new Vue({
// }).$mount('#app')