<template>
    <div v-show="loaded">
        <div class="chart-container" style="position: relative; width:100%">
            <canvas id="chart" ref="chart"></canvas>
        </div>
        <p class="text-lg">{{ meta.total_count }}st har stöttat IFK hittills</p>
    </div>
</template>
<script>
import Chart from 'chart.js'
import axios from 'axios'
export default {
    props: ['chartdata', 'options'],
    data() {
        return {
            endpoint: 'https://formularfabriken.infab.io/api/surveys/61473d02-e77a-451e-bc86-a2164ea44889/stats',
            stats: [],
            meta: [],
            loaded: false,
            chart: null,
            keys: [],
        }
    },
    async created() {
        try {
            let { data } = await axios.get(this.endpoint);
            this.meta = data.meta;
            // this.stats = data;
            let stats = [];
            let count = 0;
            let keys = [];
            // this.stats.map(() => {
            // })
            Object.keys(data.data).forEach(item => {
                count = count + data.data[item];
                // let value = data.data[item] + count;
                keys.push(item);
                stats.push(count);
            });
            this.stats = stats;
            this.keys = keys;
            this.initChart();
            this.loaded = true;
        } catch (error) {
            console.log(error)
        }
    },
    methods: {
        initChart() {
            var ctx = document
                .getElementById('chart')
                .getContext('2d')
            let vm = this
            this.chart = new Chart(ctx, {
                type: 'line',
                labels: this.keys,
                options: {
                    scales: {
                        xAxes: [{
                            display: false,
                            scaleLabel: {
                                display: true,
                                labelString: 'Month'
                            }
                        }],
                        yAxes: [{
                            display: false,
                            scaleLabel: {
                                display: true,
                                labelString: 'Value'
                            }
                        }]
                    },
                    aspectRatio: 2,
                    legend: {
                        display: false
                    },
                },
                data: {
                    labels: this.keys,
                    datasets: [
                        {
                            data: this.stats,
                            borderColor: "#EC7205",
                            pointBorderColor: "#EC7205",
                            pointBackgroundColor: "#292929",
                            backgroundColor: "#292929",
                            pointHoverBorderColor: "#EC7205",
                            pointBorderWidth: 10,
                            pointHoverRadius: 10,
                            pointHoverBorderWidth: 1,
                            pointRadius: 1,
                            borderWidth: 2,
                        }
                    ]
                },
            })
        }
    }
}
</script>