import Vue from 'vue';
import { ValidationProvider, ValidationObserver, extend, configure, localize } from 'vee-validate';


configure({
    classes: {
        valid: 'border-green-500',
        invalid: 'border-red-600',
        dirty: ['is-dirty'], // multiple classes per flag!
    }
})

// setInteractionMode('eager');

import sv from 'vee-validate/dist/locale/sv.json';
localize({
    sv: {
        names: {
            first_name: 'förnamn',
            last_name: 'efternamn',
            ssn: 'personnummer',
            name: 'namn',
            email: 'e-post',
            contact_person: 'kontaktperson',
            org_number: 'organisationsnummer',
            project_name: 'projektnamn',
            project_number: 'projektnummer',
            payment: 'ersättning',
            allowed_areas: 'marknader/platser',
            valid_until: 'Giltighetstid',
            company_name: 'företagsnamn',
            billing_post_code: 'postnummer',
            billing_city: 'stad',
            billing_country: 'land'

        },
        // messages: {
        //     required: 'Fältet är obligatoriskt',
        // }
    }

});
localize({
    sv
});
localize('sv');


import { required, email, min } from 'vee-validate/dist/rules';
extend('email', email);
extend('min', min);
extend('required', required);



import { parse, isValid, isAfter } from 'date-fns';
extend('after', {
    validate(value, { other }) {
        const parsed = parse(value, 'yyyy-MM-dd', new Date());
        if (!isValid(value)) {
            return false;
        }
        return isAfter(value, other);
    },
    message(fieldName) {
        return 'Datumet är bakåt i tiden'
    },
    castValue: value => parse(value, 'yyyy-MM-dd', new Date()),
    immediate: true,
    params: [
        {
            name: 'other',
            isTarget: true,
            cast(targetValue) {
                return new Date();
            }
        }
    ]
});
// extend('required', {
//     ...required,
//     message: 'Detta fält är obligatoriskt'
// });

// Register it globally
Vue.component('ValidationProvider', ValidationProvider);
Vue.component('ValidationObserver', ValidationObserver);
Vue.component('VeeValidate');
